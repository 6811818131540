$primary: #26579c;
$secondary: #83a1e5;
$info: #960051;
$warning: #ff8400;

$input-btn-focus-width:         .25rem !default;
$input-btn-focus-color-opacity: 0;
$input-btn-focus-box-shadow:    none;

$pagination-color: $info;
$pagination-focus-color: $info;
$pagination-active-bg: $info;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/utilities";

@import "./utilities";

@import "~bootstrap/scss/bootstrap";

.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center; }

@import "~bootstrap-icons/font/bootstrap-icons";
