$util-width: (responsive: true, property: width, class: w, values: (50pc: 50%, 100pc: 100%, 48: 48px, 64: 64px, 80: 80px, 200: 200px, 300: 300px, 400: 400px));
$util-height: (responsive: true, property: height, class: h, values: (0: 0, 100: 100%, 48: 48px, 64: 64px, 200: 200px));
$util-min-height: (responsive: true, property: min-height, class: minh, values: (0: 0, 200: 200px, 400: 400px));
$util-min-width: (responsive: true, property: min-width, class: minw, values: (0: 0, 200: 200px, 300: 300px, 400: 400px));
$util-max-height: (responsive: true, property: max-height, class: max-h, values: (0: 0, 160: 160px, 400: 400px, 500: 500px));
$utilities: map-merge($utilities, ("min-width": $util-min-width, "width": $util-width, "min-height": $util-min-height, "max-height": $util-max-height, "height": $util-height));

$enable-shadows: false;
$enable-important-utilities: false;

$theme-colors: map-merge($theme-colors, (default: $gray-400));
